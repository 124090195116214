import z from "zod";

import { CompleteWishlist } from "~/contexts/wishlist";

export const wishlistNameMaxlength: number = 80;
export const wishlistNameMinlength: number = 5;

export const wishlistId = z.number().gte(1, { message: "error.fieldRequired" });

export const wishlistName = z
  .string()
  .min(1, { message: "error.fieldRequired" })
  .min(wishlistNameMinlength, { message: "error.valueTooShort" })
  .max(wishlistNameMaxlength, { message: "error.valueTooLong" });

export const createNewWishlist = (list: CompleteWishlist[] | undefined) => {
  return z.union([
    z.object({
      wishlistId: z.literal("new"),
      wishlistName: wishlistName.refine(
        (name) => !list?.find((wishlist: CompleteWishlist) => wishlist.Name === name),
        "error.Wishlist:NameMustBeUnique"
      ),
    }),
    z.object({ wishlistId: wishlistId }),
  ]);
};
