import { useTranslations } from "next-intl";
import { DetailedHTMLProps, HTMLAttributes } from "react";

import styles from "./errors.module.scss";

type ErrorsProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  error?: any;
};

export default function EVAErrors({ error, ...rest }: ErrorsProps) {
  const t = useTranslations();
  return error ? (
    <div className={styles.errorContainer} {...rest}>
      <p>{t(`error.${error?.Error?.Type}`)}</p>
    </div>
  ) : null;
}
