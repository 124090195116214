import classNames from "classnames";
import { AnimatePresence } from "framer-motion";
import { useTranslations } from "next-intl";
import { useEffect, useMemo, useRef } from "react";

import { useConfiguration } from "~/contexts/configuration";
import { useCartProduct } from "~/hooks/use-cart";
import { algoliaAddToBag } from "~/services/algolia-utils";
import { EcommerceTrackingItemModel } from "~/types/trackings.models";

import Button from "./button";
import styles from "./product-actions.module.scss";
import ProductWishlistButton from "./product-wishlist-button";
import QuantitySelector from "./quantity-selector";

export type BasketType = "cart" | "wishlist";
export type ProductActionOutcome = "added" | "removed";

type Props = {
  productId: number;
  maxQuantity?: number;
  outOfStock?: boolean;
  hideWishlist?: boolean;
  onClickNotifyMe?: (value: boolean) => void;
  actionsContext?: string;
  trackingData?: EcommerceTrackingItemModel;
  hasPrice?: boolean;
};

export default function ProductActions(props: Props) {
  const t = useTranslations();
  const { algoliaIndexId } = useConfiguration();

  const trackingItem = props.trackingData
    ? {
        context: props.actionsContext || "",
        info: props.trackingData,
      }
    : undefined;

  const { quantityInCart, addToCart, removeFromCart } = useCartProduct(props.productId, undefined, trackingItem);

  const quantitySelectorRef = useRef<HTMLDivElement>(null);
  const fromButtonClick = useRef(false);

  const showQuantitySelector = useMemo(() => {
    return !props.outOfStock && quantityInCart > 0;
  }, [props.outOfStock, quantityInCart]);

  useEffect(() => {
    if (showQuantitySelector) {
      setTimeout(() => {
        if (fromButtonClick.current) {
          fromButtonClick.current = false;
          quantitySelectorRef.current?.focus();
        }
      }, 50);
    }
  }, [showQuantitySelector]);

  const prevQty = useRef(quantityInCart);

  // TODO to understand a11y pattern for this
  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.wrapperPrimary, { [styles.quantityOpen]: showQuantitySelector })}>
        <AnimatePresence>
          {showQuantitySelector && (
            <QuantitySelector
              productID={props.productId}
              ref={quantitySelectorRef}
              disableAdd={addToCart.status === "loading"}
              disableRemove={removeFromCart.status === "loading"}
              customClassNames={classNames(styles.quantitySelectorPrio, styles.quantitySelector)}
              maxQuantity={props.maxQuantity}
              actionContext={props.actionsContext}
              trackingData={props.trackingData}
            />
          )}
        </AnimatePresence>
        {/* If product is out of stock, display the "Notify Me" button, otherwise display the "Add to bag" button */}
        {props.hasPrice ? (
          <>
            {props.outOfStock ? (
              <Button
                className={styles.primary}
                variant="primary"
                onClick={() => props.onClickNotifyMe && props.onClickNotifyMe(true)}
                tabIndex={showQuantitySelector ? -1 : undefined}
              >
                <span className={styles.buttonText}>{t("generic.notify_me")}</span>
              </Button>
            ) : (
              <Button
                className={classNames(styles.primary, "beacon-cart-mod-btn")}
                variant="primary"
                onClick={() => {
                  fromButtonClick.current = true;
                  addToCart.mutate();
                  algoliaAddToBag(algoliaIndexId, props.productId, props.trackingData);
                }}
                tabIndex={showQuantitySelector ? -1 : undefined}
              >
                <span className={styles.buttonText}>{t("generic.add_to_bag")}</span>
              </Button>
            )}
          </>
        ) : (
          <Button
            className={styles.primary}
            variant="primary"
            disabled={true}
            aria-disabled={true}
            onClick={(evt) => evt.preventDefault()}
          >
            <span className={styles.buttonText}>{t("generic.notAvailable")}</span>
          </Button>
        )}
      </div>
      {!props.hideWishlist && (
        <ProductWishlistButton
          productId={props.productId}
          actionsContext={props.actionsContext}
          trackingData={props.trackingData}
        />
      )}
    </div>
  );
}
