import classNames from "classnames";
import { useRouter } from "next/router";
import { useState } from "react";

import { useAuthContext } from "~/contexts/auth";
import { useWishlist } from "~/contexts/wishlist";
import { EcommerceTrackingItemModel, ListProductTrackingInfo } from "~/types/trackings.models";
import appRoutes from "~/utils/app-routes";

import SelectWishlistDialog from "../myaccount/wishlist/select-wishlist-dialog";
import Button from "./button";
import Icon from "./icon";
import styles from "./product-wishlist-button.module.scss";

type Props = {
  productId: number;
  actionsContext?: string;
  trackingData?: EcommerceTrackingItemModel;
};

export default function ProductWishlistButton(props: Props) {
  const { isAuthenticated } = useAuthContext();
  const router = useRouter();
  const { list, products, toggleWishlist } = useWishlist();
  const [openWishlistSelector, setOpenWishlistSelector] = useState(false);
  const inWishlist = !!products && products.find((el) => el.product_id == props.productId);

  const fullTrackingInfo: ListProductTrackingInfo = {
    context: props.actionsContext || "",
    info: props.trackingData,
  };

  function onWishlistClick() {
    if (inWishlist) {
      const containingWishlist = list?.find((wl) =>
        wl.Products?.find((product) => product.product_id === props.productId)
      )?.ID;
      if (containingWishlist) {
        toggleWishlist(props.productId, containingWishlist, undefined, fullTrackingInfo);
      }
    } else {
      if (!isAuthenticated) {
        router.push(appRoutes.LOGIN);
      } else {
        setOpenWishlistSelector(true);
      }
    }
  }

  return (
    <>
      <Button className={styles.wishlist} variant="secondary" onClick={onWishlistClick}>
        <div className={classNames(styles.clipPath, { [styles.active]: inWishlist })}></div>
        <Icon name="heart" width={16} height={16} />
      </Button>
      <SelectWishlistDialog
        productId={props.productId}
        isOpen={openWishlistSelector}
        onClose={() => setOpenWishlistSelector(false)}
        actionsContext={props.actionsContext}
        trackingData={props.trackingData}
      />
    </>
  );
}
