import { KikUserZodSchemas as ZodSchemas } from "@kikocosmeticsorg/uc-api-nest-common-fe";
import { z } from "zod";

// Notify me (registered)
export const notifyMeValidationSchemaRegistered = z.object({
  EmailAddress: ZodSchemas.email.readonly(),
  AgreeToTerms: z.boolean().refine((value) => value === true, {
    message: "error.youMustAgreeToTermsAndConditions",
  }),
});

export type NotifyMeValidationFieldsRegistered = z.infer<typeof notifyMeValidationSchemaRegistered>;
