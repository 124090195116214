import { KikUserZodSchemas as ZodSchemas } from "@kikocosmeticsorg/uc-api-nest-common-fe";
import { z } from "zod";

// Notify me
export const notifyMeValidationSchemaGuest = z.object({
  EmailAddress: ZodSchemas.email,
  AgreeToTerms: z.boolean().refine((value) => value === true, {
    message: "error.youMustAgreeToTermsAndConditions",
  }),
});

export type NotifyMeValidationFieldsGuest = z.infer<typeof notifyMeValidationSchemaGuest>;
