import { zodResolver } from "@hookform/resolvers/zod";
import { KikUserZodSchemas as ZodSchemas } from "@kikocosmeticsorg/uc-api-nest-common-fe";
import classNames from "classnames";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import {
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { useAuthContext } from "~/contexts/auth";
import { useServerSideProps } from "~/contexts/serverSideProps";
import useAuth from "~/hooks/use-auth";
import { useNotifyMe } from "~/hooks/use-notifyMe";
import Logger from "~/services/logger/logger";
import { NotifyMeValidationFieldsGuest, notifyMeValidationSchemaGuest } from "~/shared/notify-me/guest-fields.model";
import {
  NotifyMeValidationFieldsRegistered,
  notifyMeValidationSchemaRegistered,
} from "~/shared/notify-me/registered-fields.model";
import Editorials from "~/types/editorials";
import { assertEditorialType } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import Button from "../common/button";
import Icon from "../common/icon";
import LinkWrapper from "../common/link-wrapper";
import Checkbox from "../form/checkbox";
import InputField from "../form/input-field";
import styles from "./notifyMe.module.scss";

export type NotifyMeProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  productId: number;
  showClose?: boolean;
  showCloseIcon?: boolean;
  onClose?: () => void;
  showSubmitButton?: boolean;
};

export type NotifyMeRef = HTMLDivElement & {
  resetSubscribedState: () => void;
};

export default forwardRef<HTMLDivElement, NotifyMeProps>(function NotifyMe(
  { productId, onClose, showSubmitButton = true, className, showClose = true, showCloseIcon, ...rest },
  ref
) {
  const { serverSideProps } = useServerSideProps();
  const router = useRouter();
  const [alreadySubscribed, setAlreadySubscribed] = useState<boolean>(false);
  const [subscribeNotificationJustSubmitted, setSubscribeNotificationJustSubmitted] = useState<boolean>(false);
  const t = useTranslations();
  const { user } = useAuthContext();
  const URLRef = useRef<string | undefined>(router.asPath);

  const contentUtils = serverSideProps.contentUtils;
  assertEditorialType<Editorials.ContentUtils>(contentUtils, "contentUtils");

  const privacyPolicyLink = textUtils.sanitizeContentfulUrl(contentUtils.fields.privacyPolicyLink, router);

  const NotifyMeValidationSchema = user ? notifyMeValidationSchemaRegistered : notifyMeValidationSchemaGuest;
  type NotifyMeValidationFields = typeof user extends Record<string, any>
    ? NotifyMeValidationFieldsRegistered
    : NotifyMeValidationFieldsGuest;

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<NotifyMeValidationFields>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldFocusError: false,
    defaultValues: {
      AgreeToTerms: false,
      EmailAddress: user?.EmailAddress,
    },
    resolver: zodResolver(NotifyMeValidationSchema),
  });

  const { getStockNotification, createStockNotification } = useNotifyMe({
    productId: productId,
    emailAddress: user?.EmailAddress || getValues("EmailAddress"),
  });

  const resetSubscribedState = useCallback(() => {
    setAlreadySubscribed(false);
    setSubscribeNotificationJustSubmitted(false);
  }, []);

  const onGetStockNotification = (getStockNotificationResponse: EVA.Core.StockNotificationResponse | unknown) => {
    if (getStockNotificationResponse !== null) {
      setAlreadySubscribed(true);
      return;
    }

    resetSubscribedState();

    createStockNotification({
      onSuccess: (data) => {
        setSubscribeNotificationJustSubmitted(true);
        Logger.instance.debug("createStockNotification success", data);
      },
      onError: (err) => {
        Logger.instance.warn("createStockNotification error", err);
      },
    });
  };

  // Notify Me form subsmission
  // First check that the user has not already subscribed for notifications for the same product
  const onSubmit: SubmitHandler<NotifyMeValidationFields> = async (data: NotifyMeValidationFields) => {
    getStockNotification({
      onSuccess: (resp) => {
        onGetStockNotification(resp);
        Logger.instance.debug("getStockNotification success", data);
      },
      onError: (err) => {
        Logger.instance.warn("getStockNotification error", err);
      },
    });
  };

  const onEmailChange = () => resetSubscribedState();

  // reset the notify me form when the URL changes
  useEffect(() => {
    if (router.asPath !== undefined && URLRef.current && URLRef.current !== router.asPath) {
      resetSubscribedState();
      URLRef.current = router.asPath;
    }
  }, [resetSubscribedState, router.asPath]);

  return (
    <div ref={ref} className={classNames(styles.notifyMeContainer, className)} {...rest}>
      {!subscribeNotificationJustSubmitted ? (
        <>
          <p className={styles.notifyMeTitile}>{t("generic.notify_me_alert_me")}</p>
          <p className={styles.notifyMeEnterEmail}>{t("generic.notifyMeDescription")}</p>
          <form onSubmit={handleSubmit(onSubmit)} noValidate className={styles.notifyMeForm}>
            <InputField
              {...register("EmailAddress")}
              label={t("generic.form.EmailAddress")}
              type="email"
              error={
                errors.EmailAddress?.message
                  ? t(errors.EmailAddress?.message, {
                      fieldName: t("generic.form.EmailAddress"),
                      maxLength: ZodSchemas.emailMaxlength,
                    })
                  : ""
              }
              onClearErrors={() => clearErrors("EmailAddress")}
              onChange={() => onEmailChange()}
              className={styles.inputEmail}
              maxLength={ZodSchemas.emailMaxlength}
              defaultValue={user?.EmailAddress}
              value={user?.EmailAddress}
              readOnly={!!user?.EmailAddress}
            />

            {!alreadySubscribed ? (
              <>
                <Checkbox
                  {...register("AgreeToTerms")}
                  label={t("generic.notifyMeDisclaimer")}
                  className={styles.newsletterCheckbox}
                  defaultChecked={false}
                  error={errors.AgreeToTerms?.message ? t(errors.AgreeToTerms?.message) : ""}
                />
                <LinkWrapper className={styles.privacyPolicyLink} href={privacyPolicyLink} target="_blank">
                  {t("generic.privacyPolicy")}
                </LinkWrapper>
              </>
            ) : null}

            {alreadySubscribed ? (
              <div className={styles.alreadySubscribed}>
                <Icon name="done" aria-hidden width={16} height={16} className={styles.alreadySubscribedIcon} />
                <p className={styles.alreadySubscribedTitle}>{t("generic.notify_me_you_already_subscribed")}</p>
                <p className={styles.alreadySubscribedDescription}>{t("generic.notify_me_already_subscribed")}</p>
              </div>
            ) : null}

            {!alreadySubscribed && showSubmitButton ? (
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
                className={styles.submitButton}
              >
                {t("generic.notify_me")}
              </Button>
            ) : null}
          </form>
        </>
      ) : (
        <p className={styles.notifyMeEnterEmail}>
          {t.rich("generic.notify_me_subscription_success", {
            email: (chunks) => <strong>{user?.EmailAddress || getValues("EmailAddress")}</strong>,
            br: (chunks) => <br />,
          })}
        </p>
      )}

      {showClose && (
        <Button
          className={classNames(styles.closeButton, { [styles.closeButtonIcon]: showCloseIcon })}
          lookAsLink
          onClick={() => {
            resetSubscribedState();
            onClose && onClose();
          }}
        >
          {showCloseIcon ? (
            <Icon name="close" width={20} height={20} />
          ) : alreadySubscribed || subscribeNotificationJustSubmitted ? (
            t("generic.close")
          ) : (
            t("generic.notifyme.notnow")
          )}
        </Button>
      )}
    </div>
  );
});
