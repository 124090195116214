import { hooks } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { MutateOptions, useMutation } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { useAuthContext } from "~/contexts/auth";
import Eva from "~/services/eva";

type UseNotifyMeProps = {
  productId: number;
  emailAddress?: string;
};

export function useNotifyMe({ productId, emailAddress }: UseNotifyMeProps) {
  const router = useRouter();
  const { user } = useAuthContext();
  const guestStockNotificationsGet = hooks.useCallService({
    service: Core.GetStockNotification,
  });
  const userStockNotificationsGet = hooks.useCallService({
    service: Core.GetStockNotificationForCurrentUser,
  });
  const guestStockNotificationsCreate = hooks.useCallService({
    service: Core.CreateStockNotification,
  });
  const userStockNotificationCreate = hooks.useCallService({
    service: Core.CreateStockNotificationForCurrentUser,
  });

  const getStockNotificationForCurrentUser = useMutation(
    async () => await userStockNotificationsGet({ ProductID: productId })
  );

  const getStockNotificationGuest = useMutation(async () => {
    const organizationUnitId = await Eva.getOrganizationUnitId(router.locale!);
    return await guestStockNotificationsGet({
      EmailAddress: emailAddress!,
      ProductID: productId,
      OrganizationUnitID: organizationUnitId,
    });
  });

  const createStockNotificationForCurrentUser = useMutation(
    async () => await userStockNotificationCreate({ ProductID: productId })
  );

  const createStockNotificationGuest = useMutation(async () => {
    const organizationUnitId = await Eva.getOrganizationUnitId(router.locale!);
    return await guestStockNotificationsCreate({
      EmailAddress: emailAddress!,
      ProductID: productId,
      OrganizationUnitID: organizationUnitId,
    });
  });

  // Exposed methods
  const getStockNotification = async ({ onSuccess, onError }: MutateOptions) => {
    if (user) {
      return getStockNotificationForCurrentUser.mutate(undefined, {
        onSuccess: onSuccess,
        onError: onError,
      });
    }

    return getStockNotificationGuest.mutate(undefined, {
      onSuccess: onSuccess,
      onError: onError,
    });
  };

  const createStockNotification = async ({ onSuccess, onError }: MutateOptions) => {
    if (user) {
      return createStockNotificationForCurrentUser.mutate(undefined, {
        onSuccess: onSuccess,
        onError: onError,
      });
    }

    return createStockNotificationGuest.mutate(undefined, {
      onSuccess: onSuccess,
      onError: onError,
    });
  };

  return {
    getStockNotification,
    createStockNotification,
  };
}
