import { RadioGroup } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { hooks } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import classNames from "classnames";
import { HTTPError } from "ky";
import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import Button from "~/components/common/button";
import Dialog from "~/components/common/dialog";
import Icon from "~/components/common/icon";
import EVAErrors from "~/components/form/evaErrors";
import InputField from "~/components/form/input-field";
import { useWishlist } from "~/contexts/wishlist";
import { createNewWishlist, wishlistNameMaxlength, wishlistNameMinlength } from "~/shared/wishlist/zod-schemas.class";
import { EcommerceTrackingItemModel, ListProductTrackingInfo } from "~/types/trackings.models";

import styles from "./wishlist.module.scss";

type Props = {
  productId: number;
  isOpen: boolean;
  onClose: () => void;
  savedForLater?: boolean;
  actionsContext?: string;
  trackingData?: EcommerceTrackingItemModel;
};

export default function SelectWishlistDialog({
  productId,
  isOpen,
  onClose,
  savedForLater,
  actionsContext,
  trackingData,
}: Props) {
  const t = useTranslations();

  const { list, toggleWishlist, refetch, removeProdFromWishlist, addProdToWishlist, savedForLaterList } = useWishlist();
  const createWishlist = hooks.useCallService({ service: Core.CreateUserWishlist });

  const [moveToWishlistError, setMoveToWishlistError] = useState("");
  const [createWishlistError, setCreateWishlistError] = useState<any>();

  const selectWishlistValidationSchema = createNewWishlist(list);
  type ValidationSchema = z.infer<typeof selectWishlistValidationSchema>;

  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors, isSubmitting, isValid },
  } = useForm<ValidationSchema>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(selectWishlistValidationSchema),
    defaultValues: {
      wishlistId: -1,
    },
  });

  const watchList = watch("wishlistId");

  const fullTrackingInfo: ListProductTrackingInfo = {
    context: actionsContext || "",
    info: trackingData,
  };

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setValue("wishlistName", "");
      }, 500);
    }
  }, [isOpen, setValue]);

  const submit = async (data: ValidationSchema) => {
    setMoveToWishlistError("");
    setCreateWishlistError(undefined);
    // assuming the 0 is the create new wishlist
    if (data.wishlistId == "new") {
      const response = await createWishlist(
        {
          Name: data.wishlistName,
        },
        undefined,
        {
          onError: async (error) => {
            if (error.name === "HTTPError") {
              const jsonError = await (error as HTTPError).response.json();
              setCreateWishlistError(jsonError);
            }
          },
        }
      );
      if (response?.ID) {
        const updatedList = await refetch();
        if (updatedList.data) {
          await toggleWishlist(productId, response.ID, updatedList.data, fullTrackingInfo);
          if (savedForLater && savedForLaterList) {
            await removeProdFromWishlist(productId, savedForLaterList.ID);
          }
          onClose();
        }
      }
    } else {
      const response = await addProdToWishlist(productId, data.wishlistId, fullTrackingInfo);
      if (response.errorMessage) {
        setMoveToWishlistError(response.errorMessage);
      } else {
        if (savedForLater && savedForLaterList) {
          await removeProdFromWishlist(productId, savedForLaterList.ID, fullTrackingInfo);
        }
        onClose();
      }
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={
        <>
          <Icon name="heart" />
          <span>{t("generic.myaccount.wishlist.select_wishlist")}</span>
        </>
      }
      onClose={onClose}
      customStyles={styles}
    >
      <form onSubmit={handleSubmit(submit)} className={styles.form}>
        {/* Render a list of the current user's wishlists */}
        <Controller
          control={control}
          name="wishlistId"
          render={({ field }) => (
            <RadioGroup {...field} className={styles.radioList}>
              {list?.map((el) => (
                <RadioGroup.Option key={el.ID} value={el.ID}>
                  {({ checked }) => (
                    <div className={styles.option}>
                      <Icon name={checked ? "radio-selected" : "radio-unselected"} width={20} height={20} />
                      <RadioGroup.Label as="p" className={styles.optionLabel}>
                        {el.Name} ({el?.count})
                      </RadioGroup.Label>
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
              <hr className={styles.separator} />
              <RadioGroup.Option value={"new"} key={"new"}>
                {({ checked }) => (
                  <>
                    <div className={styles.option}>
                      <Icon name={checked ? "radio-selected" : "radio-unselected"} width={20} height={20} />
                      <RadioGroup.Label as="p" className={styles.optionLabel}>
                        {t("generic.myaccount.wishlist.create_cta")}
                      </RadioGroup.Label>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            </RadioGroup>
          )}
        />
        {/* If users select "Create a new wishlist" */}
        {watchList === "new" && (
          <>
            <InputField
              {...register("wishlistName")}
              type="text"
              label={t("generic.myaccount.wishlist.field")}
              error={
                "wishlistName" in errors
                  ? t(errors.wishlistName?.message, {
                      fieldName: t("generic.myaccount.wishlist.field"),
                      maxLength: wishlistNameMaxlength,
                      minLength: wishlistNameMinlength,
                    })
                  : undefined
              }
              clearValue={() => {
                setCreateWishlistError(undefined);
                setValue("wishlistName", "");
                clearErrors("wishlistName");
              }}
              className={styles.wishlistName}
              externalError={createWishlistError?.Error?.Type}
            />
            <EVAErrors error={createWishlistError} />
          </>
        )}
        <hr className={classNames(styles.separator, styles.externalSeparator)} />
        {/* handle errors */}
        {errors.wishlistId && <p className={styles.error}>{t("generic.needselect")}</p>}
        {moveToWishlistError && <p className={styles.error}>{moveToWishlistError}</p>}
        <Button
          className={styles.submitModal}
          variant="secondary"
          type="submit"
          disabled={!isValid}
          aria-disabled={!isValid}
          loading={isSubmitting}
        >
          {t("generic.myaccount.wishlist.save_into")}
        </Button>
      </form>
    </Dialog>
  );
}
